import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useAppContext } from "../../hooks";
import { imageUrl } from "../../config/config";
import Iconify from "../../components/Iconify";

function AddToCart() {
  const {
    cartItems,
    handleDeleteItemFromCart,
    handleUpdateItemQuantityFromCart,
    loading,
  } = useAppContext();

  const handleDelete = (id) => {
    handleDeleteItemFromCart(id);
  };
  const handleChangeQuantity = (e, id) => {
    const formData = new FormData();
    const value = e.target.value <= 0 ? 1 : e.target.value;
    formData.append("quantity", value);
    formData.append("_method", "put");
    handleUpdateItemQuantityFromCart(formData, id);
  };
  const getTotalAmount = () => {
    return cartItems.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
  };
  return (
    <>
      {cartItems?.length == 0 ? (
        <>
          <div className="text-center" style={{ marginTop: "14%" }}>
            <h3>No Items in Cart</h3>
          </div>
        </>
      ) : (
        <>
          {loading == true ? (
            <Box>
              <CircularProgress
                sx={{
                  display: "flex",
                  marginTop: "20%",
                  marginLeft: "50%",
                  color: "black",
                }}
              />
            </Box>
          ) : (
            <div className="row p-3 mx-auto">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div
                  className="main container p-4 mt-5"
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.199)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Shopping Cart</h3>
                    <h5>{cartItems?.length} Total Items</h5>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mx-5">
                    <h6>Product Detail</h6>
                    <h6>Price</h6>
                    <h6>Quantity</h6>
                    <h6>Total Price</h6>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      {cartItems?.map((val) => {
                        return (
                          <>
                            <Card
                              style={{
                                border: "1px solid silver",
                                padding: "8px",
                                boxShadow: "none",
                                marginTop: "10px",
                              }}
                            >
                              <div className="row">
                                <div className="text-end">
                                  <Iconify
                                    icon={"entypo:cross"}
                                    width={20}
                                    height={20}
                                    onClick={() => handleDelete(val?.cart_id)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <div className="col-lg-4 col-md-4 d-flex align-items-center  gap-2">
                                  <img
                                    src={imageUrl + val?.image}
                                    style={{
                                      width: "130px",
                                      height: "140px",
                                      borderRadius: "10px",
                                    }}
                                    alt=""
                                  />
                                  <div>
                                    <h5>{val?.title}</h5>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-2   d-flex align-items-center justify-content-center">
                                  <h5>{val?.price}</h5>
                                </div>
                                <div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center">
                                  <TextField
                                    size="small"
                                    type="number"
                                    onChange={(e) =>
                                      handleChangeQuantity(e, val?.cart_id)
                                    }
                                    value={val?.quantity}
                                    sx={{ width: "100px" }}
                                  />
                                </div>
                                <div className="col-lg-2 col-md-2 d-flex align-items-center justify-content-center">
                                  <h5>{val?.price * val?.quantity}</h5>
                                </div>
                              </div>
                            </Card>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ">
                <Card
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.199)",
                    borderRadius: "7px",
                    marginTop: "3rem",
                    padding: "15px",
                  }}
                >
                  <h4 className="text-center mt-3 mb-4">Order Summary</h4>
                  <hr />
                  <div>
                    <h5>Total Products({cartItems?.length})</h5>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <h5>Total Amount</h5>
                    <h6>${getTotalAmount().toFixed(2)}</h6>
                  </div>
                  <button
                    style={{
                      border: "none",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    Place Order
                  </button>
                </Card>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AddToCart;
