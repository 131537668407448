import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Logout } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { UserLogout } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../hooks";
import LoginIcon from "@mui/icons-material/Login";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 3,
    padding: "0 3px",
  },
}));

export default function Navbar() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { cartItems, localStorageData } = useAppContext();
  const [data, setData] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = async () => {
    const resp = await UserLogout();
    if (resp.status == true) {
      localStorage.clear();
      enqueueSnackbar(resp.message, { variant: "success" });
      navigate("/login");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const getData = JSON.parse(localStorage.getItem("user_data"));
    setData(getData);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between p-3 bg-light">
        <Link to={"/"}>
          <h3>Logo</h3>
        </Link>
        <div className="d-flex gap-2">
          <Link to="/cart">
            <IconButton aria-label="cart">
              <StyledBadge
                badgeContent={cartItems?.length > 0 ? cartItems?.length : 0}
                color="error"
              >
                <ShoppingCartIcon />
              </StyledBadge>
            </IconButton>
          </Link>
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    src=""
                    alt={data?.name}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem sx={{ color: "grey" }}>{data?.email}</MenuItem>
              <Divider />
              {!localStorageData ? (
                <MenuItem onClick={handleLogin}>
                  <ListItemIcon>
                    <LoginIcon fontSize="small" />
                  </ListItemIcon>
                  Login
                </MenuItem>
              ) : (
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
