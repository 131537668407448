import React, { useState, useEffect } from "react";
import {
  addItemsToCart,
  cartListing,
  deleteItemsFromCart,
  updateItemQuantityFromCart,
} from "../DAL/Cart/Cart";
import { useSnackbar } from "notistack";

const Context = React.createContext();
const AppContext = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [localStorageData, setLocalStorageData] = useState();
  //------------------------------------------------------

  const getCartListing = async () => {
    setLoading(true);
    const resp = await cartListing();
    if (resp?.status == true) {
      setCartItems(resp?.data);
      setLoading(false);
    }
  };
  const handleAddCart = async (id) => {
    const resp = await addItemsToCart(id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      getCartListing();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleDeleteItemFromCart = async (id) => {
    const resp = await deleteItemsFromCart(id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      getCartListing();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleUpdateItemQuantityFromCart = async (data, id) => {
    const resp = await updateItemQuantityFromCart(data, id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      getCartListing();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  //------------------------------------------------------
  useEffect(() => {
    const data = localStorage.getItem("token");
    setLocalStorageData(data);
    getCartListing();
  }, []);
  //------------------------------------------------------
  const bundle = {
    handleAddCart,
    cartItems,
    handleDeleteItemFromCart,
    handleUpdateItemQuantityFromCart,
    loading,
    localStorageData,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
