import { Card, FormHelperText, TextField } from "@mui/material";
import React, { useState } from "react";
import { UserLogin, UserRegister } from "../../DAL/Auth/auth";
import { useNavigate } from "react-router-dom";
import ToastNotifier from "../../components/ToastNotifier";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export default function Register({ setVal }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("email", inputs?.email);
    formData.append("password", inputs?.password);
    formData.append("name", inputs?.name);
    formData.append("phone", inputs?.phone);
    const resp = await UserRegister(formData);

    if (resp.status == true) {
      enqueueSnackbar(resp.message, { variant: "success" });
      setInputs({
        email: "",
        password: "",
        name: "",
        phone: "",
      });
      setVal(1);
    } else {
      setLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setErrors(resp?.message);
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
    }
  };
  return (
    <>
      <div className="mt-3">
        <TextField
          label="Name *"
          size="small"
          name="name"
          value={inputs?.name}
          fullWidth
          onChange={handleChange}
        />
        <FormHelperText className="text-danger" style={{ fontSize: "12px" }}>
          {errors?.name && errors?.name[0]}
        </FormHelperText>
      </div>
      <div className="mt-3">
        <TextField
          label="Email *"
          size="small"
          name="email"
          value={inputs?.email}
          fullWidth
          onChange={handleChange}
        />
        <FormHelperText className="text-danger" style={{ fontSize: "12px" }}>
          {errors?.email && errors?.email[0]}
        </FormHelperText>
      </div>
      <div className="mb-4 mt-4">
        <TextField
          label="Phone *"
          size="small"
          value={inputs?.phone}
          name="phone"
          fullWidth
          onChange={handleChange}
        />
        <FormHelperText className="text-danger" style={{ fontSize: "12px" }}>
          {errors?.phone && errors?.phone[0]}
        </FormHelperText>
      </div>
      <div className="mb-4 mt-4">
        <TextField
          label="Password *"
          size="small"
          value={inputs?.password}
          name="password"
          fullWidth
          onChange={handleChange}
        />
        <FormHelperText className="text-danger" style={{ fontSize: "12px" }}>
          {errors?.password && errors?.password[0]}
        </FormHelperText>
      </div>
      <div>
        <LoadingButton
          fullWidth
          size="large"
          className="Add-button bg-secondary"
          variant="contained"
          loading={loading}
          onClick={handleRegister}
        >
          {loading == true ? "Register.." : "Register"}
        </LoadingButton>
      </div>
    </>
  );
}
