import AppRouter from "./routes";
import "./App.css";
import { SnackbarProvider } from "notistack";
import React from "react";

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
