import { invokeApi } from "../../utils";

export const cartListing = async () => {
  const requestObj = {
    path: `/api/carts/products`,
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return invokeApi(requestObj);
};

export const addItemsToCart = async (id) => {
  const requestObj = {
    path: `/api/add_to_cart/${id}`,
    method: "POSt",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return invokeApi(requestObj);
};

export const deleteItemsFromCart = async (id) => {
  const requestObj = {
    path: `/api/cart_delete/${id}`,
    method: "DELETE",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return invokeApi(requestObj);
};

export const updateItemQuantityFromCart = async (data, id) => {
  const requestObj = {
    path: `/api/carts/quantity/${id}`,
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    postData: data,
  };
  return invokeApi(requestObj);
};
