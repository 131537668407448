import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppContext } from "./hooks";
import { SnackbarProvider } from "notistack";
import { IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const root = ReactDOM.createRoot(document.getElementById("root"));
const notiStackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notiStackRef.current.closeSnackbar(key);
};
root.render(
  <BrowserRouter>
    <SnackbarProvider
      hideIconVariant
      ref={notiStackRef}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      TransitionComponent={Slide}
      maxSnack={3}
      autoHideDuration={2000}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon htmlColor="white" />
        </IconButton>
      )}
    >
      <AppContext>
        <App />
      </AppContext>
    </SnackbarProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
