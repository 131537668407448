import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProductListing from "./Pages/Products/productListing";
import AddToCart from "./Pages/AddToCart/AddToCart";
import Login from "./Pages/Auth/Login";
import Layout from "./Layout/layout";
import ProductDetail from "./Pages/Products/ProductDetail";

export default function AppRouter() {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/products"></Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"></Navigate>;
    }
  };
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          {/* <Route path="/" element={<Authentication />} /> */}
          <Route path="/" element={<ProductListing />} />
          <Route path="/cart" element={<AddToCart />} />
          <Route
            path="/products/product-detail/:id"
            element={<ProductDetail />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}
