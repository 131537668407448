import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../components/Iconify";
import { imageUrl } from "../../config/config";
import { useAppContext } from "../../hooks";
import { useSnackbar } from "notistack";

export default function ProductDetail() {
  const state = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { handleAddCart, cartItems, localStorageData } = useAppContext();

  const handleNav = () => {
    navigate(-1);
  };
  const filterItem = cartItems.filter((ele) => ele.id == data?.id);
  const handleAddToCart = (id) => {
    if (!localStorageData) {
      enqueueSnackbar("Please login", { variant: "error" });
      navigate("/login");
      return;
    } else {
      handleAddCart(id);
    }
  };

  useEffect(() => {
    if (state?.state) {
      setData(state?.state);
    }
  }, []);
  return (
    <>
      <div className="container mt-4">
        <Stack direction="row">
          <IconButton onClick={handleNav} className="me-1">
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4">Product Detail</Typography>
        </Stack>

        <div
          className="mb-3 mt-3"
          style={{
            maxWidth: "auto",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.200)",
          }}
        >
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={imageUrl + data?.image}
                className="img-fluid rounded-start"
                style={{ height: "auto", width: "100%" }}
                alt="..."
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <div className="">
                  <h5 className="card-title text-dark">{data?.title}</h5>
                  <h6 className="card-title text-dark">{data?.price}</h6>
                </div>
                <hr style={{ color: "black" }} />
                <p className="card-text text-dark">{data?.description}</p>
                <div className="text-end">
                  <button
                    style={{
                      border: "none",
                      padding: "10px",
                      borderRadius: "7px",
                      backgroundColor: "black",
                      color: "white",
                    }}
                    onClick={() => handleAddToCart(data?.id)}
                  >
                    Add to cart({filterItem?.length})
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
