import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllProducts } from "../../DAL/Products/Product";
import { imageUrl } from "../../config/config";
import { addItemsToCart } from "../../DAL/Cart/Cart";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../hooks";

export default function ProductListing() {
  const navigate = useNavigate();
  const { handleAddCart, cartItems, localStorageData } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleCart = (val) => {
    navigate(`/products/product-detail/${val?.id}`, { state: val });
  };
  const [productData, setProductData] = useState([]);

  const getProducts = async () => {
    setLoading(true);
    const resp = await getAllProducts();
    if (resp.status == true) {
      setProductData(resp?.products);
      setLoading(false);
    }
  };

  const handleAddToCart = (id) => {
    if (!localStorageData) {
      enqueueSnackbar("Please login", { variant: "error" });
      navigate("/login");
      return;
    } else {
      handleAddCart(id);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      {loading == true ? (
        <Box>
          <CircularProgress
            sx={{
              display: "flex",
              marginTop: "20%",
              marginLeft: "50%",
              color: "black",
            }}
          />
        </Box>
      ) : (
        <div className="container mx-auto">
          <Typography variant="h4" className="mt-4">
            Products Listing
          </Typography>
          <div className="row mt-2 mb-4">
            {productData?.map((val) => {
              const filterItem = cartItems.filter((ele) => ele.id == val?.id);
              console.log(filterItem, "dsjhfgsdgjfjkd", cartItems);
              return (
                <>
                  <div className="col-lg-3 mt-3">
                    <Card sx={{ maxWidth: "100%" }} className="card">
                      <img
                        src={imageUrl + val?.image}
                        alt=""
                        onClick={() => handleCart(val)}
                        style={{ cursor: "pointer" }}
                      />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {val?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ color: "text.secondary" }}
                        >
                          {val?.price}
                        </Typography>
                        <div className="mt-2">
                          <button
                            style={{
                              border: "none",
                              padding: "10px",
                              borderRadius: "7px",
                              backgroundColor: "black",
                              color: "white",
                              width: "100%",
                            }}
                            onClick={() => handleAddToCart(val?.id)}
                          >
                            Add to cart({filterItem.length})
                          </button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
