import { invokeApi } from "../../utils";

export const getAllProducts = async (data) => {
  const requestObj = {
    path: `/api/products`,
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return invokeApi(requestObj);
};
