import { Card, FormHelperText, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { UserLogin } from "../../DAL/Auth/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Register from "./Register";
import { LoadingButton } from "@mui/lab";

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState([]);
  const [val, setVal] = useState(0);
  const [loading, setloading] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const formData = new FormData();
    formData.append("email", inputs?.email);
    formData.append("password", inputs?.password);
    const resp = await UserLogin(formData);
    if (resp.status == true) {
      localStorage.setItem("user_data", JSON.stringify(resp?.user));
      localStorage.setItem("token", resp?.user?.token);
      enqueueSnackbar(resp.message, { variant: "success" });
      navigate("/");
    } else {
      setloading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setErrors(resp?.message);
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
    }
  };

  console.log(errors, "sdjfsdhjkfhkdjsk");
  return (
    <>
      <div className="loginMain">
        <Card
          sx={{
            minWidth: 500,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.199)",
            p: 3,
            marginTop: "2rem",
            borderRadius: "15px",
          }}
        >
          <div className="d-flex align-items-center gap-5 justify-content-center ">
            <Typography
              variant="h5"
              className={val == 0 ? "active" : "pb-2"}
              onClick={() => setVal(0)}
              style={{ cursor: "pointer" }}
            >
              Sign In
            </Typography>
            <Typography
              variant="h5"
              className={val == 1 ? "active" : "pb-3"}
              onClick={() => setVal(1)}
              style={{ cursor: "pointer" }}
            >
              Sign Up
            </Typography>
          </div>
          {val == 0 ? (
            <>
              <div className="mt-3">
                <TextField
                  label="Email *"
                  size="small"
                  name="email"
                  value={inputs?.email}
                  fullWidth
                  onChange={handleChange}
                />
                <FormHelperText
                  className="text-danger"
                  style={{ fontSize: "12px" }}
                >
                  {errors?.email && errors?.email[0]}
                </FormHelperText>
              </div>
              <div className="mb-4 mt-4">
                <TextField
                  label="Password *"
                  size="small"
                  value={inputs?.password}
                  name="password"
                  fullWidth
                  onChange={handleChange}
                />
                <FormHelperText
                  className="text-danger"
                  style={{ fontSize: "12px" }}
                >
                  {errors?.password && errors?.password[0]}
                </FormHelperText>
              </div>
              <div>
                <LoadingButton
                  fullWidth
                  size="large"
                  className="Add-button bg-secondary"
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {loading == true ? "Login.." : "Login"}
                </LoadingButton>
              </div>
            </>
          ) : (
            <Register setVal={setVal} />
          )}
        </Card>
      </div>
    </>
  );
}
