import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import Navbar from "../Pages/Navbar/Navbar";

export default function Layout() {
  // if (!localStorage.getItem("token")) {
  //   return <Navigate to="/login"> </Navigate>;
  // }
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}
